import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Script } from 'gatsby';
import { Helmet } from 'react-helmet';
import { castArray } from 'lodash';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

import Layout from 'components/layout';
import PageContent from 'components/page-content';
import ProjectHeader from 'components/project-header/project-header';
import Button from 'components/blocks/button/button';
import ProjectPost from '../components/project-list/project-post';
import { Center, Container } from '../components/project-list/project-list.css';

const ProjectTemplate = ({ data, pageContext, location }) => {
  const {
    title,
    excerpt,
    category,
    content,
    similarProjects,
  } = data.contentfulProject;

  const thumbnailImage = null;

  const pageTitleFull = title
    ? `${title} | The Neighborhood Design Center`
    : 'The Neighborhood Design Center';
  const fullDescription =
    (excerpt && excerpt.excerpt.substring(0, 155)) ||
    (content &&
      content.raw &&
      documentToPlainTextString(content.raw).substring(0, 155)) ||
    null;
  const imageUrl =
    (thumbnailImage &&
      `${thumbnailImage?.gatsbyImageData?.images?.fallback?.src}`) ||
    null;

  return (
    <Layout location={location}>
      <Helmet>
        {pageTitleFull && <meta content={pageTitleFull} property="og:title" />}
        {pageTitleFull && <meta content={pageTitleFull} name="twitter:title" />}
        {pageTitleFull && <title>{pageTitleFull}</title>}
        {fullDescription && (
          <meta content={fullDescription} name="description" />
        )}
        {fullDescription && (
          <meta content={fullDescription} property="og:description" />
        )}
        {fullDescription && (
          <meta content={fullDescription} name="twitter:description" />
        )}
        {pageTitleFull && (
          <meta content={pageTitleFull} name="twitter:text:title" />
        )}
        {imageUrl && <meta content={imageUrl} property="og:image" />}
        {imageUrl && <meta content="1024" property="og:image:width" />}
        {imageUrl && <meta content="512" property="og:image:height" />}
        {imageUrl && <meta content={imageUrl} name="twitter:image" />}
        {imageUrl && <meta content="1024" name="twitter:image:width" />}
        {imageUrl && <meta content="512" name="twitter:image:height" />}
        {imageUrl && <meta content={imageUrl} property="og:image" />}
        {imageUrl && <meta content="1024" property="og:image:width" />}
        {imageUrl && <meta content="512" property="og:image:height" />}
      </Helmet>
      <div className="bg-white">
        <ProjectHeader
          title={title}
          type={castArray(category)}
          // date={date}
          pageColor="green"
        // previous={previous}
        // next={next}
        />
        <PageContent
          margin="my-0 sm:my-0"
          content={content}
          pageColor="green"
        />
        {similarProjects && similarProjects.length > 0 && (
          <Center>
            <h3 className="text-center text-5p5xl text-brand-green font-extralight pt-32">
              Similar Projects
            </h3>
            <Container className="pt-10">
              {similarProjects.map((project, i) => (
                <ProjectPost i={i} {...project} />
              ))}
            </Container>
          </Center>
        )}
        <section className="w-full flex flex-col items-center relative -mx-2px z-1 bg-white">
          <div className="text-center my-10 sm:my-20">
            <Button
              text="Work with us"
              link="/work-with-us"
              isInternal={true}
              isInline
            />
            <Button
              text="Case Studies"
              link="/our-work"
              isInternal={true}
              isInline
            />
          </div>
        </section>
      </div>
      <Script id="mc-embedded-global-id">{`
        const subscribeButton = document.getElementById('mc-embedded-subscribe');
        const emailInput = document.getElementById('mce-EMAIL');
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        const checkboxGroup = document.getElementById('mc-checkbox-group');

        if (!subscribeButton || !emailInput || checkboxes.length === 0 || !checkboxGroup) {
          throw new Error('Uno o más elementos necesarios no están disponibles.');
        }

        // Helper function to update button state and styles
        function updateButtonState() {
          const emailFilled = emailInput.value.trim() !== '';
          const checkboxChecked = Array.from(checkboxes).some(checkbox => checkbox.checked);
          const isFormValid = emailFilled && checkboxChecked;

          subscribeButton.disabled = !isFormValid;

          if (isFormValid) {
            subscribeButton.classList.remove('disabled');
          } else {
            subscribeButton.classList.add('disabled');
          }
        }

        // Initial button state and styles
        subscribeButton.classList.add('disabled');

        // Attach event listeners
        emailInput.addEventListener('input', updateButtonState);
        checkboxes.forEach(checkbox => checkbox.addEventListener('change', updateButtonState));

        updateButtonState();
      `}</Script>
    </Layout>
  );
};

ProjectTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default ProjectTemplate;

export const query = graphql`
  query ProjectBySlug($slug: String!) {
    contentfulProject(slug: { eq: $slug }) {
      id
      title
      category
      published
      similarProjects {
        id
        published
        title
        slug
        category
        date
        images {
          id
          description
          gatsbyImageData(width: 1500, placeholder: BLURRED)
        }
        text {
          raw
        }
      }
      content {
        raw
        references {
          __typename
          ... on ContentfulAList {
            contentful_id
            columnCount
            includeIcon
            list {
              raw
            }
            textColor
          }
          ... on ContentfulAButton {
            contentful_id
            text
            link
            asset {
              id
              file {
                url
              }
            }
            opensNewTab
          }
          ... on ContentfulMailchimpEmbed {
            contentful_id
            label
            code {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulASpacer {
            contentful_id
            height
            includeDivider
          }
          ... on ContentfulUtilityBlock {
            contentful_id
            text
            type
          }
          ... on ContentfulAnImageText {
            contentful_id
            image {
              id
              description
              gatsbyImageData(width: 1500, placeholder: BLURRED)
            }
            imageSide
            imageType
            textDescription: text {
              raw
            }
          }
          ... on ContentfulAPullquote {
            contentful_id
            image {
              id
              description
              gatsbyImageData(width: 1500, placeholder: BLURRED)
            }
            quote {
              id
              quote
            }
            attribution
          }
          ... on ContentfulAnImageGroupSlideshowCollage {
            contentful_id
            images {
              id
              description
              gatsbyImageData(width: 1500, placeholder: BLURRED)
            }
            displayAs
            blockWidth
            showCaption
          }
          ... on ContentfulSubscribeAndSocial {
            contentful_id
            facebookLink
            instagramLink
            twitterLink
            linkedinLink
            youtubeLink
            newsletterTitle
            socialLinksTitle
          }
          ... on ContentfulFeaturedVolunteers {
            contentful_id
            featuredVoluteersHeading
          }
          ... on ContentfulAList {
            contentful_id
            columnCount
            includeIcon
            list {
              raw
            }
            textColor
          }
          ... on ContentfulAsset {
            contentful_id
            title
            description
            file {
              url
              fileName
              contentType
            }
            gatsbyImageData(width: 1500, placeholder: BLURRED)
          }
          ... on ContentfulAnImageOrVideo {
            contentful_id
            image {
              id
              description
              gatsbyImageData(width: 1500, placeholder: BLURRED)
            }
            showCaption
            videoUrl
            blockWidth
          }
        }
      }
    }
  }
`;
