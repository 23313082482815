import nanostyled from 'nanostyled';
import { Link } from 'gatsby';

export const Container = nanostyled('section', {
  base: 'w-full max-w-4xl mx-auto typography',
  bg: '',
});

export const Margin = nanostyled('div', {
  base: 'mx-4 xs:mx-10 sm:mx-20',
});

export const Type = nanostyled('h4', {
  base: 'mb-0 mr-2 text-brand-green !text-xl ',
});

export const Date = nanostyled('span', {
  base:
    'ml-2 mb-0 text-xl transition duration-200 ease-in-out group-hover:text-white',
});

export const PrevLink = nanostyled(Link, {
  base:
    'font-micro p-4 font-bold text-xxs uppercase fixed left-0 top-1/2 rotate-prev z-30 hidden sm:flex group items-center justify-center flex-col',
  color: '',
});

export const PrevTitle = nanostyled('div', {
  base: 'normal-case text-sm mt-2 hidden group-hover:block',
});

export const NextLink = nanostyled(Link, {
  base:
    'font-micro p-4 font-bold text-xxs uppercase fixed right-0 top-1/2 rotate-next z-30 hidden sm:flex group items-center justify-center flex-col',
  color: '',
});

export const NextTitle = nanostyled('div', {
  base: 'normal-case text-sm mb-2 hidden group-hover:block',
});

export const StyledLink = nanostyled(Link, {
  base: 'category',
});
